import {isCSR} from '@jobseeker/lotus/lib/utils';
import {NextSeo, NextSeoProps} from 'next-seo';
import {getMeta, getSyncPageTitle, getSyncPageDescription} from '@utils/seo';

type SEOProps = {
  seoConfig?: NextSeoProps;
  paginationEnabled?: boolean;
};

const SeoLayout = ({seoConfig, paginationEnabled = false}: SEOProps) => {
  const notAllowedIndexAndFollow =
    process.env.NEXT_PUBLIC_NO_INDEX === 'true' ? true : false;

  if (isCSR() && paginationEnabled && seoConfig) {
    const keyToDelete = ['prev', 'next'];

    seoConfig.additionalLinkTags = seoConfig.additionalLinkTags?.filter(
      tag => !keyToDelete.includes(tag.rel)
    );
  }

  return (
    <NextSeo
      nofollow={notAllowedIndexAndFollow}
      noindex={notAllowedIndexAndFollow}
      {...seoConfig}
    />
  );
};

type SeoIntrupProps = {
  pageNo: number;
  nextPage: boolean;
  type:
    | 'jobfeed'
    | 'jobdetail'
    | 'reset-password'
    | 'allnotification'
    | 'recruiterview'
    | 'epoch'
    | 'unsubscribe'
    | 'personalize-jobfeed'
    | 'interviews';
};

const SeoIntrup = ({pageNo, nextPage, type}: SeoIntrupProps) => {
  const currentUrl = window.location.href?.split('?')[0];

  const Intrup: {
    [key: string]: () => object;
  } = {
    ['jobfeed']: () => {
      const descriptionContent = getMeta('description') || '';

      const seoUpdate: NextSeoProps = {
        title: getSyncPageTitle(document.title, pageNo),
        canonical: pageNo > 1 ? `${currentUrl}?page=${pageNo}` : currentUrl,
      };

      if (pageNo > 1 && descriptionContent) {
        seoUpdate['description'] = getSyncPageDescription(
          descriptionContent,
          pageNo
        );
      }

      if (pageNo <= 1) {
        seoUpdate['additionalLinkTags'] = [
          {
            rel: 'next',
            href: `${currentUrl}?page=${pageNo + 1}`,
          },
        ];
      } else if (pageNo > 1 && nextPage) {
        seoUpdate['additionalLinkTags'] = [
          {
            rel: 'prev',
            href:
              pageNo - 1 <= 1 ? currentUrl : `${currentUrl}?page=${pageNo - 1}`,
          },
          {
            rel: 'next',
            href: `${currentUrl}?page=${pageNo + 1}`,
          },
        ];
      } else if (!nextPage) {
        seoUpdate['additionalLinkTags'] = [
          {
            rel: 'prev',
            href:
              pageNo - 1 <= 1 ? currentUrl : `${currentUrl}?page=${pageNo - 1}`,
          },
        ];
      }

      return seoUpdate;
    },
    ['reset-password']: () => {
      return {
        title: 'Reset Password | iimjobs.com',
      };
    },
    ['allnotification']: () => {
      return {
        title: 'Notifications',
        canonical: currentUrl,
      };
    },
    ['recruiterview']: () => ({
      title: 'Recruiter View',
      canonical: currentUrl,
    }),
    ['epoch']: () => {
      return {
        title: 'EPOCH Case Challenge',
      };
    },
    ['unsubscribe']: () => {
      return {
        title: 'Unsubscribe Email',
      };
    },
    ['personalize-jobfeed']: () => {
      return {
        title: 'Personalize Jobfeed',
      };
    },
    ['interviews']: () => {
      return {
        title: 'Interviews',
        canonical: currentUrl,
      };
    },
  };

  const getSeoIntrupByType = (type: string) => Intrup[type]();

  return <NextSeo {...getSeoIntrupByType(type)} />;
};

export {SeoLayout, SeoIntrup};
